import React, { useEffect, useContext } from 'react';
import { SimulatorContext } from "src/components/Simulator/SimulatorContext";
import * as styles from "./styles.form.module.css";

const HubSpotForm = () => {
    const urlParams = new URLSearchParams(typeof window !== "undefined" ? window.location.search : '');
    const formId = urlParams.get('formId');
    const { profiles } = useContext(SimulatorContext);
    const script = typeof document !== "undefined" ? document.createElement('script') : {};
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = true;

    useEffect(() => {
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        document.body.appendChild(script);

        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "8519019",
                    formId,
                    target: "#hubspot-form",
                    onFormReady: ($form) => {
                       const setFieldValue = (fieldName, value) => {
                            const field = $form.querySelector(`input[name="${fieldName}"]`);
                            if (field) {
                                field.value = value ? value : '';
                                const event = new Event('input', { bubbles: true });
                                field.dispatchEvent(event);
                            }
                        };

                        setFieldValue('firstname', profiles[0]?.first_name);
                        setFieldValue('lastname', profiles[0]?.last_name);
                        setFieldValue('email', profiles[0]?.email);
                        setFieldValue('phone', profiles[0]?.phone);
                        setFieldValue('rut_empresa', profiles[0]?.numberId);
                    },
                    onFormSubmit: function($form) {
                        if (profiles.length > 0) {
                            const container = document.querySelector("#container");

                            container.insertAdjacentHTML("beforeend", "<a style='margin-top:20px; display: block;'  href='https://inversionistas.cumplo.cl/'>Volver a mis inversiones</a>");
                        }
                    }
                });
            }
        };
    }, [profiles]);

    return (
        <div className={styles.formContainer}>
            <div id="hubspot-form"> </div>
        </div>
    );
};

export default HubSpotForm;
